<ng-container>
  <div class="home-page">
    <div class="container">
      <app-spinner [isLoading]="false">
        <div class="banner-wrapper">
          <app-banner-slider [data]="data.banners"></app-banner-slider>
        </div>

        <div class="popular-wrapper">
          <app-showcase [title]="'Popular'" [list]="showcases" [isLoading]="isLoadingShowcase"></app-showcase>
        </div>

        <div class="game-list-wrapper">
          <app-game-list></app-game-list>
        </div>

        <div class="latest-news-wrapper">
          <app-latest-news [data]="articles"></app-latest-news>
        </div>

        <div class="why-wrapper">
          <app-why-info [data]="data.whys"></app-why-info>
        </div>
      </app-spinner>
    </div>
  </div>
</ng-container>
