<header class="app-header">
  <div class="container">
    <div class="inner-section">
      <div class="logo-wrapper">
        <a routerLink="/">
          <picture>
            <source media="(max-width: 768px)" srcset="assets/images/logo-mobile.png">
            <img class="logo" src="assets/images/logo.png" alt="logo vocerku">
          </picture>
        </a>
      </div>

      <div class="search-wrapper">
        <div class="box-search">
          <div class="input-group">
            <span class="input-group-text" [ngClass]="searchResultActive || searchFocus ? 'active' : ''">
              <fa-icon [icon]="faSearch" class="icon"></fa-icon>
            </span>

            <input #searchElement [(ngModel)]="productParams.search" (keyup.enter)="handleSearchEnter()" (focus)="onSearchFocus($event)" (blur)="handleBlurSearch()" (ngModelChange)="handleSearch($event)"
                   type="text" class="form-control" placeholder="Cari mobile legend, google play..">
          </div>
        </div>

        <div class="search-result-wrapper" [ngClass]="searchResultActive ? 'active' : ''">
          <div class="search-result-game-list">

            <a class="result-game-item" *ngFor="let result of productResults" (click)="handleResultClick(result.permalink)">
              <div class="result-game-wrapper">
                <div class="result-game-image-wrapper">
                  <img
                    class="result-game-image"
                    [src]="result.photo?.url"
                    [alt]="result.photo?.name"
                  />
                </div>
                <div class="result-game-detail-wrapper">
                  <span class="title">{{result.name}}</span>
                </div>
              </div>
            </a>

            <h6 *ngIf="productResults.length < 1" class="text-center my-3">No Result</h6>

            <a *ngIf="totalData > productResults.length" class="text-link result-game-more-link" (click)="handleSearchEnter()">View All</a>

          </div>
        </div>
      </div>

      <div class="action-wrapper">
        <div class="content">
          <a [routerLink]="['/auth/login']" class="btn btn-main btn-sm" *ngIf="!hideLoginButton()">Masuk</a>

          <div class="profile-wrapper" *ngIf="authService.isLoggedIn">
            <div ngbDropdown display="dynamic" placement="bottom-end">
              <div id="profile-dropdown" ngbDropdownToggle>
                <div class="icon-wrapper">
                  <img class="icon" src="assets/images/icon/account.png" alt="profile icon">
                  <fa-icon [icon]="faAngleDown" class="caret"></fa-icon>
                </div>
              </div>
              <div ngbDropdownMenu aria-labelledby="profile-dropdown" class="dropdown-menu dropdown-menu-end">
                <a ngbDropdownItem [routerLink]="['/setting']" [queryParams]="{tab: 'account'}">Pengaturan</a>
                <hr/>
                <span ngbDropdownItem (click)="authService.doLogout()">Keluar</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
