import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnChanges {
  @Input() show: boolean = false;
  @Input() type: string = '';
  @Input() amount: number = 0;
  @Input() url: string = '';
  @Input() vaBankLogo: string = '';
  @Input() vaName: string = '';
  @Input() vaNumber: string = '';
  @Output() onClose = new EventEmitter<boolean>();

  @ViewChild('content', { static: false }) private content: any;

  modalReference: any
  title: string = ''

  constructor(private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges) {
    this.title = 'QR Payment'

    if (this.type === 'VA') {
      this.title = 'VA Payment'
    } else if (this.type === 'EMONEY') {
      this.title = 'E-Money Payment'
    }

    if (this.show) {
      this.modalReference = this.modalService.open(this.content, { centered: true, backdrop: 'static' });
    }
  }

  closeModal() {
    this.modalReference.close();
    this.onClose.emit(false);
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }
}
