<ng-template #content let-modal>
  <div class="default-modal">
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <table class="table-payment-detail" *ngIf="type === 'VA'">
        <tr>
          <td>Bank</td>
          <td>:</td>
          <td><img [src]="vaBankLogo" class="bank-logo"></td>
        </tr>
        <tr>
          <td>VA Name</td>
          <td>:</td>
          <td class="table-value">{{vaName}}</td>
        </tr>
        <tr>
          <td>VA Number</td>
          <td>:</td>
          <td class="table-value">{{vaNumber}}</td>
        </tr>
        <tr>
          <td>Total Payment</td>
          <td>:</td>
          <td class="table-value">Rp {{amount | number: '1.0-0'}}</td>
        </tr>
      </table>

      <div *ngIf="type === 'QR'">
        <div class="amount">
          Rp {{amount | number: '1.0-0'}}
        </div>
        <img class="qr-image" [src]="url" alt="qr code" style="width: 100%">
      </div>

      <ng-container *ngIf="type === 'EMONEY'">
        <div class="amount">
          Rp {{amount | number: '1.0-0'}}
        </div>

        <div class="input-group mb-3">
          <input type="text" class="form-control" disabled [value]="url">
        </div>

        <div class="text-center">
          <button class="btn btn-main btn-sm" (click)="goToLink(url)">Click to pay</button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
