import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

/**
 *  Call updateTitle() from app.component.ts contructor.
 *  Example app.component.ts:
 constructor(private meta: MetaService) {
        this.meta.updateTitle();
    }
 * Optional override title with passing a title parameter.
 * Format routes as follow:
 {
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'About',
      description:'Description Meta Tag Content'
    }
  },
 **/

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) { route = route.firstChild; }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
        this.titleService.setTitle(event['title'] + ' | VocerKu');
      });
    } else {
      this.titleService.setTitle(title + ' | VocerKu');
    }
  }
}
