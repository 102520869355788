import { Component, Input, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-card-transaction-history',
  templateUrl: './card-transaction-history.component.html',
  styleUrls: ['./card-transaction-history.component.scss'],
})
export class CardTransactionHistoryComponent implements OnInit {
  @Input() status = '';
  @Input() date = '';
  @Input() transactionId = 0;
  @Input() totalPrice = 0;
  @Input() totalBeforeDiscount = 0;
  @Input() promoCode: null | string = null;
  @Input() productName = '';
  @Input() paymentType: string = '';
  @Input() paymentUrl = '';
  @Input() paymentMethod = '';
  @Input() vaBankLogo: string = '';
  @Input() vaName: string = '';
  @Input() vaNumber: string = '';

  showPaymentModal = false;
  showQrCode = false;
  showVaModal = false;

  constructor(
    public router: Router,
  ) {}

  ngOnInit(): void {}

  openPayment(): void {
    this.showPaymentModal = true;
  }

  onClosePaymentModal(val: boolean) {
    this.showPaymentModal = val;
  }
}
