<app-modal-payment
  [show]="showPaymentModal"
  [amount]="paymentAmount"
  [type]="selectedPayment?.type ?? ''"
  [url]="paymentUrl"
  [vaBankLogo]="vaBankLogo"
  [vaName]="vaName"
  [vaNumber]="vaNumber"
  (onClose)="onClosePaymentModal($event)"
>
</app-modal-payment>

<ng-template #content let-modal>
  <div class="default-modal">
    <div class="modal-header">
      <h4 class="modal-title">Pakai Kode Promo</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form class="form" [formGroup]="formPromo" (ngSubmit)="onSubmitPromo()">
        <div class="inline-form-control">
          <div class="form-control-wrapper">
            <input
              type="text"
              class="form-control"
              placeholder="Masukkan kode promo"
              formControlName="promo_code"
              [ngClass]="{ 'is-invalid': submitted && fp['promo_code'].errors }"
              (ngModelChange)="promoErrors.promo_code = promoErrorMessage.promo_code"
            >
            <fa-icon
              *ngIf="!fp['promo_code'].errors && fp['promo_code'].value"
              class="icon"
              [icon]="faTimes"
              (click)="removePromoCode()"
            ></fa-icon>
          </div>

          <button type="submit" class="btn btn-main">
            <span *ngIf="!loadingPromo">Terapkan</span>
            <div class="spinner-border" role="status" *ngIf="loadingPromo">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>

        <div *ngIf="submittedPromo && fp['promo_code'].errors" class="invalid-feedback">
          <div *ngIf="fp['promo_code'].errors">{{promoErrors.promo_code}}</div>
        </div>

      </form>
    </div>
  </div>
</ng-template>

<ng-container>
  <div class="purchase-page">

    <app-spinner [isLoading]="productLoading && variantLoading && paymentLoading && isLoadingPage">
      <div class="container">
        <div class="content-wrapper">
          <div class="detail-section">
            <div class="detail-wrapper">
              <div class="card-detail">
                <h4 class="title">{{product?.name}}</h4>

                <div class="thumbnail-wrapper">
                  <img class="thumbnail" [src]="product?.photo_link" [alt]="product?.name">
                </div>
              </div>

              <div class="payment-term">
                <div class="header-title">
                  <span class="title">Cara Pembayaran</span>
                </div>
                <span [innerHTML]="data.how_to_pay"></span>
              </div>
            </div>
          </div>

          <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="info-section">
              <div class="info-box" *ngIf="product?.category_name === 'Game'">
                <div class="header-panel">
                  <span class="number"></span>
                  <h4 class="title">Informasi Pemesan</h4>
                </div>
                <div class="body-panel">
                  <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="isErrorUser">User tidak dapat ditemukan</ngb-alert>

                  <div class="mb-3">
                    <label class="form-label">User ID</label>
                    <div class="form-control-icon">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan user ID"
                        formControlName="user_id"
                        [ngClass]="{ 'is-invalid': submitted && f['user_id'].errors }"
                      >
                      <fa-icon
                        class="icon"
                        [icon]="faCircleExclamation"
                        placement="bottom"
                        tooltipClass="tooltip-main"
                        ngbTooltip="Digit di depan kurung. Contoh: 21213123123"
                      ></fa-icon>
                    </div>

                    <div *ngIf="submitted && f['user_id'].errors" class="invalid-feedback">
                      <div *ngIf="f['user_id'].errors">{{errors.user_id}}</div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Server ID</label>
                    <div class="form-control-icon">
                      <select
                        class="form-control"
                        formControlName="server_id"
                        [ngClass]="{ 'is-invalid': submitted && f['server_id'].errors }"
                        *ngIf="isSelectServer"
                      >
                        <option value="">Pilih Server ID</option>
                        <option *ngFor="let item of servers" [value]="item.value">{{item.label}}</option>
                      </select>

                      <input
                        *ngIf="!isSelectServer"
                        type="text"
                        class="form-control"
                        placeholder="Masukkan server ID"
                        formControlName="server_id"
                        [ngClass]="{ 'is-invalid': submitted && f['server_id'].errors }"
                      >
                    </div>

                    <div *ngIf="submitted && f['server_id'].errors" class="invalid-feedback">
                      <div *ngIf="f['server_id'].errors">{{errors.server_id}}</div>
                    </div>
                  </div>

                  <div *ngIf="username">
                    Username yang tertera pada server adalah <strong>{{username}}</strong>
                  </div>
                </div>
              </div>

              <div class="info-box">
                <div class="header-panel">
                  <span class="number"></span>
                  <h4 class="title">Pilih Jumlah {{product?.unit ?? 'Voucher / Service'}}</h4>
                </div>

                <div class="body-panel">
                  <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="submitted && f['variant_id'].errors">{{errors.variant_id}}</ngb-alert>

                  <div class="custom-radio-list">
                    <label *ngFor="let item of variants">
                      <input formControlName="variant_id" type="radio" [value]="item.id" name="variant_id">
                      <span>{{item.name}}</span>
                    </label>
                  </div>

                  <div *ngIf="!variants.length" class="text-center">
                    Produk ini tidak memiliki {{(product?.unit ?? 'voucher / service')}}
                  </div>
                </div>
              </div>

              <div class="info-box">
                <div class="header-panel">
                  <span class="number"></span>
                  <h4 class="title">Kode Promo</h4>
                </div>
                <div class="body-panel">
                  <div class="voucher-button" (click)="openVoucherModal(content)">
                    <div class="text-icon-wrapper">
                      <img class="icon" src="assets/images/icon/voucher.png" alt="voucher icon">
                      <span class="text">{{ form.get('promo_code')?.value || 'Gunakan Kode Promo' }}</span>
                      <img class="check" *ngIf="form.get('promo_code')?.value" src="assets/images/icon/check.png" alt="check icon">
                    </div>

                    <fa-icon [icon]="faAngleRight" class="caret"></fa-icon>
                  </div>

                  <div *ngIf="submitted && f['promo_code'].errors" class="invalid-feedback">
                    <div *ngIf="f['promo_code'].errors">{{errors.promo_code}}</div>
                  </div>
                </div>
              </div>

              <div class="info-box">
                <div class="header-panel">
                  <span class="number"></span>
                  <h4 class="title">Pilih Metode Pembayaran</h4>
                </div>
                <div class="body-panel">
                  <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="submitted && f['payment_id'].errors">{{errors.payment_id}}</ngb-alert>

                  <div *ngIf="showPaymentDisclaimer" class="text-center small mb-3">
                    *Maximum promo yang dapat digunakan tidak bisa sampai dibawah Rp 10.000
                  </div>

                  <div class="payment-radio-list">
                    <label class="radio-input" *ngFor="let item of paymentOptions">
                      <input type="radio" formControlName="payment_id" [value]="item.id" name="payment_id" [disabled]="!selectedVariant">
                      <span class="option">
                        <label class="label" *ngIf="item?.discountPercentage">Promo</label>
                        <img class="logo" [src]="item.photo_link" alt="{{item.name}} icon">
                        <span class="discount" *ngIf="item?.discountPercentage">Diskon {{item?.discountPercentage | number : '1.0-0'}}% <s>Rp {{item?.beforeDiscount | number: '1.0-0'}}</s></span>
                        <span class="total">Rp {{item.total | number: '1.0-0'}}</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-main btn-full" [disabled]="loading || !selectedVariant || !selectedPayment">
                <span *ngIf="!loading">Lanjutkan ke Pembayaran</span>
                <div class="spinner-border" role="status" *ngIf="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </form>


        </div>
      </div>
    </app-spinner>
  </div>
</ng-container>
