import { Component, OnInit } from '@angular/core';
import {
  faAngleDown,
  faAngleRight,
  faCircleExclamation,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { VariantService } from '../../../api/services/variant.service';
import { Variant } from '../../../api/models/variant';
import { ProductService } from '../../../api/services/product.service';
import { Product } from '../../../api/models/product';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PaymentMethodService } from '../../../api/services/payment-method.service';
import { Payment } from '../../../api/models/payment-method';
import * as _ from 'lodash';
import { OrderService } from '../../../api/services/order.service';
import { ErrorService } from '../../../services/error.service';
import { Promo } from '../../../api/models/order';
import { PagePurchase } from '../../../api/models/page-purchase';
import { PageService } from '../../../api/services/page.service';
import { MetaService } from '../../../services/meta.service';
import { AuthService } from '../../../shared/auth/auth.service';
import { ToastService } from '../../../services/toast.service';
import {SelectService} from "../../../api/services/select.service";
import {Select} from "../../../api/models/select";
import {debounceTime} from "rxjs";

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  faAngleDown = faAngleDown;
  faTimes = faTimes;
  faAngleRight = faAngleRight;
  faCircleExclamation = faCircleExclamation;

  paymentOptions: any[] = [];

  permalink: string = '';
  product: Product | undefined;
  variants: Variant[] = [];
  payments: Payment[] = [];
  servers: Select[] = [];

  formErrorMessage = {
    user_id: 'Harap masukkan User ID',
    server_id: 'Harap masukkan Server ID',
    variant_id: 'Harap memilih voucher / service terlebih dahulu!',
    promo_code: 'Kode promo tidak dapat digunakan, silahkan coba lagi',
    payment_id: 'Harap memilih metode pembayaran terlebih dahulu!'
  }

  form!: FormGroup;
  loading = false;
  submitted = false;
  errors = this.formErrorMessage;

  isSelectServer = true;

  productLoading = true;
  variantLoading = true;
  paymentLoading = true;

  promoErrorMessage = {
    promo_code: 'Harap masukkan kode promo',
  };

  formPromo!: FormGroup;
  loadingPromo = false;
  submittedPromo = false;
  promoErrors = this.promoErrorMessage;

  selectedVariant: Variant | undefined;
  selectedPromo: Promo | undefined;
  selectedPayment: any | undefined;

  data: PagePurchase = {
    meta_title: '',
    meta_keyword: '',
    meta_description: '',
    how_to_pay: '',
  };
  isLoadingPage = true;

  showPaymentDisclaimer = false;
  showPaymentModal = false;
  paymentAmount = 0;
  paymentUrl: string = '';

  vaBankLogo: string = ''
  vaName: string = ''
  vaNumber: string = ''

  username = '';
  isErrorUser = false;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private productService: ProductService,
    private variantService: VariantService,
    private paymentMethodService: PaymentMethodService,
    private orderService: OrderService,
    private pageService: PageService,
    private metaService: MetaService,
    private fb: FormBuilder,
    private authService: AuthService,
    public router: Router,
    private errorService: ErrorService,
    private toastService: ToastService,
    private selectService: SelectService,
  ) {}

  ngOnInit(): void {
    this.getData();
    this.permalink = this.route.snapshot.paramMap.get('permalink') || '';

    this.formPromo = this.fb.group({
      promo_code: ['', [Validators.required]],
    });

    this.form = this.fb.group({
      user_id: ['', [Validators.required]],
      server_id: ['', [Validators.required]],
      variant_id: ['', [Validators.required]],
      promo_code: [''],
      payment_id: ['', [Validators.required]],
    });

    this.productService.get(this.permalink).subscribe({
      next: (res) => {
        this.product = res.data;

        this.errors.variant_id = `Harap memilih `+ (this.product?.unit ?? 'voucher / service') +` terlebih dahulu!`

        if (this.product?.category_name != 'Game') {
          this.form.removeControl('user_id');
          this.form.removeControl('server_id');
        } else {
          if (this.permalink === 'mobile-legends') {
            this.form.get('user_id')?.valueChanges.pipe(debounceTime(1500))
              .subscribe(dataValue => {
                this.getValidateUser()
              });

            this.form.get('server_id')?.valueChanges.pipe(debounceTime(1500))
              .subscribe(dataValue => {
                this.getValidateUser()
              });
          }
        }

        this.productLoading = false;

        this.getProductVariant();
        this.getPaymentMethod();
      },
      error: (e) => {},
    });

    this.form.get('variant_id')?.valueChanges.subscribe((val) => {
      this.selectedVariant = _.find(this.variants, { id: val });
      this.isSelectServer = this.selectedVariant?.is_select_server ?? false;

      if (this.product?.category_name == 'Game') {
        this.setCategoryGameServerID()
      }

      this.calculatePaymentPrice();
    });

    this.form.get('payment_id')?.valueChanges.subscribe((val) => {
      this.selectedPayment = _.find(this.paymentOptions, { id: val });
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get fp(): { [key: string]: AbstractControl } {
    return this.formPromo.controls;
  }

  getValidateUser(): void {
    let userId = this.f['user_id'].value;
    let serverId = this.f['server_id'].value;

    this.username = '';

    if (userId && serverId) {
      this.orderService.validateUser({
        user_id: userId,
        server_id: serverId
      }).subscribe({
        next: (res) => {
          if (res?.code === 200) {
            this.isErrorUser = false;
            this.username = res?.username;
          } else if(res?.code === 201) {
            this.isErrorUser = true
          }
        },
        error: (e) => {},
      })
    }
  }

  getProductVariant(): void {
    this.variantService
      .all({
        product: this.permalink,
        is_active: 1
      })
      .subscribe({
        next: (res) => {
          this.variants = res.data;
          this.variantLoading = false;
          if (this.variants.length) {
            this.form.controls['variant_id'].setValue(this.variants[0].id);
          } else {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }
        },
        error: (e) => {},
      });
  }

  getPaymentMethod(): void {
    this.paymentMethodService.all().subscribe({
      next: (res) => {
        this.payments = res.data;
        this.paymentLoading = false;
        this.calculatePaymentPrice();
      },
      error: (e) => {},
    });
  }

  getProductServer(): void {
    this.selectService.server(Number(this.product?.id), this.selectedVariant?.provider).subscribe({
      next: (res) => {
        this.servers = res.data
      },
      error: (e) => {}
    })
  }

  getData(): void {
    this.pageService.page('purchase').subscribe({
      next: (res) => {
        this.data = res.data as PagePurchase;
        this.metaService.updateTitle(this.data.meta_description);
        this.isLoadingPage = false;
      },
      error: (e) => {},
    });
  }

  calculatePaymentPrice() {
    this.paymentOptions = [];
    const variantSellPrice = this.selectedVariant?.sell_price || 0;

    this.showPaymentDisclaimer = false;

    this.payments.map((item) => {
      let discountPercentage = 0;
      let discountValue = 0;

      let promo = this.selectedPromo;

      if (promo) {
        let validPromo = true;

        if (promo.promo_type === 'Payment Method') {
          if (!promo.payment_ids.includes(item.id)) validPromo = false
        }

        if (validPromo) {
          if (promo.discount_type === 'Percentage') {
            discountPercentage = promo.percentage;
            discountValue = variantSellPrice*discountPercentage/100;

            if (discountValue > promo.max_discount && promo.max_discount) discountValue = promo.max_discount
          } else {
            discountValue = promo.discount_amount;
            discountPercentage = discountValue/variantSellPrice*100;
          }
        }
      }

      let total = variantSellPrice - discountValue;

      if (total < 10000) {
        this.showPaymentDisclaimer = true;
        total = 10000;
      }

      let payment = {
        id: item.id,
        name: item.name,
        type: item.type,
        photo_link: item.photo?.url || '',
        discountPercentage: discountPercentage,
        beforeDiscount: variantSellPrice,
        total: total
      };

      this.paymentOptions.push(payment);
    });
  }

  setCategoryGameServerID() {
    if (this.isSelectServer) {
      this.form.controls['server_id'].setValue('');
      this.getProductServer()
    }
  }

  onSubmit(): void {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/auth/login']);
      return;
    }

    this.loading = true;
    this.submitted = true;
    if (this.form?.invalid || this.isErrorUser) {
      this.loading = false;
      return;
    }

    let appendParams = {};

    if (this.product?.category_name === 'Game') {
      appendParams = {
        game_user_id: this.f['user_id'].value,
        server_id: String(this.f['server_id'].value),
      };
    }

    this.orderService
      .createOrder({
        ...appendParams,
        variant_id: this.f['variant_id'].value,
        payment_id: this.f['payment_id'].value,
        promo_code: this.f['promo_code'].value,
        price: this.selectedVariant?.sell_price,
      })
      .subscribe({
        next: (res) => {
          this.submitted = false;

          this.toastService.show({
            text: 'Purchase successful, please complete payment',
            classname: 'bg-success text-light',
          });

          this.paymentAmount = res.data.grand_total;

          if (this.selectedPayment.type === 'QR' || this.selectedPayment.type === 'EMONEY') {
            this.paymentUrl = res.data.nexuspay_payment_url;
          } else if (this.selectedPayment.type === 'VA') {
            this.vaBankLogo = this.selectedPayment.photo_link
            this.vaName = res.data.nexuspay_va_name;
            this.vaNumber = res.data.nexuspay_va_number;
          }

          this.showPaymentModal = true;
        },
        error: (e) => {
          if (e.error.errors) {
            let errors = this.errorService.transformToKeyValue(e.error.errors)

            this.errors = {
              ...this.errors,
              ...errors
            }

            Object.keys(errors).map((objectKey) => {
              if (this.form.controls[objectKey]) this.form.controls[objectKey].setErrors({'incorrect': true});
            });
          }

          this.toastService.show({
            text: 'Terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
            classname: 'bg-danger text-light',
            delay: 10000
          });

          this.loading = false;
        },
      });
  }

  removePromoCode(): void {
    this.f['promo_code'].setValue('');
    this.fp['promo_code'].setValue('');
    this.formPromo.reset();

    this.selectedPromo = undefined;
    this.calculatePaymentPrice();
  }

  onSubmitPromo(): void {
    this.loadingPromo = true;
    this.submittedPromo = true;
    if (this.formPromo?.invalid) {
      this.loadingPromo = false;
      return;
    }

    let promoCode = this.fp['promo_code'].value;

    this.orderService
      .promo({
        product_id: this.product?.id,
        promo_code: promoCode,
      })
      .subscribe({
        next: (res) => {
          this.selectedPromo = res;
          this.loadingPromo = false;
          this.modalService.dismissAll();
          this.f['promo_code'].setValue(promoCode);

          this.calculatePaymentPrice();
        },
        error: (e) => {
          if (e.error.errors) {
            let errors = this.errorService.transformToKeyValue(e.error.errors);

            this.promoErrors = {
              ...this.promoErrors,
              ...errors,
            };

            Object.keys(errors).map((objectKey) => {
              this.formPromo.controls[objectKey].setErrors({ incorrect: true });
            });
          }

          this.loadingPromo = false;
        },
      });
  }

  openVoucherModal(content: any) {
    this.formPromo.reset();
    this.fp['promo_code'].setValue(this.f['promo_code'].value);
    this.modalService.open(content, { centered: true });
  }

  onClosePaymentModal(val: boolean) {
    this.showPaymentModal = val;
    this.formPromo.reset();
    this.router.navigate(['/setting'], {
      queryParams: { tab: 'order-history' },
    });
  }
}
