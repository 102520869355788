import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

// Module
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';

// Component
import { AppComponent } from './app.component';
import { PublicLayoutComponent } from './components/layouts/public-layout/public-layout.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HeaderComponent } from './components/layouts/parts/header/header.component';
import { FooterComponent } from './components/layouts/parts/footer/footer.component';
import { BannerSliderComponent } from './components/sections/banner-slider/banner-slider.component';
import { ShowcaseComponent } from './components/sections/showcase/showcase.component';
import { CardGameComponent } from './components/cards/card-game/card-game.component';
import { GameListComponent } from './components/sections/game-list/game-list.component';
import { LatestNewsComponent } from './components/sections/latest-news/latest-news.component';
import { CardNewsComponent } from './components/cards/card-news/card-news.component';
import { WhyInfoComponent } from './components/sections/why-info/why-info.component';
import { LoginComponent } from './components/pages/auth/login/login.component';
import { AuthLayoutComponent } from './components/pages/auth/auth-layout/auth-layout.component';

// Library
import { SwiperModule } from 'swiper/angular';
import { RegisterComponent } from './components/pages/auth/register/register.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';
import { SuccessComponent } from './components/pages/auth/register/success/success.component';
import { ResetPasswordComponent } from './components/pages/auth/reset-password/reset-password.component';
import { PurchaseComponent } from './components/pages/purchase/purchase.component';
import { ArticleComponent } from './components/pages/article/article.component';
import { ArticleDetailComponent } from './components/pages/article-detail/article-detail.component';
import { SettingComponent } from './components/pages/setting/setting.component';
import { SettingAccountComponent } from './components/sections/setting-account/setting-account.component';
import { TransactionHistoryComponent } from './components/sections/transaction-history/transaction-history.component';
import { CardTransactionHistoryComponent } from './components/cards/card-transaction-history/card-transaction-history.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ResultComponent } from './components/pages/result/result.component';
import { TermsAndConditionComponent } from './components/pages/terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import {AuthInterceptor} from "./shared/auth/authconfig.interceptor";
import {DatePipe, DecimalPipe} from "@angular/common";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast.component';
import {PaymentComponent} from "./components/modal/payment/payment.component";

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BannerSliderComponent,
    ShowcaseComponent,
    CardGameComponent,
    GameListComponent,
    LatestNewsComponent,
    CardNewsComponent,
    WhyInfoComponent,
    AuthLayoutComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SuccessComponent,
    ResetPasswordComponent,
    PurchaseComponent,
    ArticleComponent,
    ArticleDetailComponent,
    SettingComponent,
    SettingAccountComponent,
    TransactionHistoryComponent,
    CardTransactionHistoryComponent,
    NotFoundComponent,
    ResultComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    SpinnerComponent,
    ToastComponent,
    PaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    SwiperModule,
    FormsModule,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    CountdownModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
